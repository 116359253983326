"use client"

import { useEffect } from "react"

import SquareCta from "~/components/ui/SquareCta"
import { logger } from "~/utils/logger"

import * as css from "./styles.css"

export type ErrorHandlerProps = { title?: string; error: Error; reset: () => void }

function ErrorHandler({ title = "Something went wrong!", reset, error }: ErrorHandlerProps) {
  useEffect(() => {
    // Log the error to an error reporting service
    logger.warn(error)
  }, [error])

  return (
    <div className={css.ErrorHandler} data-comp="UI/ErrorHandler">
      <div className={css.content}>
        <h2 className={css.title}>{title}</h2>
        <SquareCta
          onClick={
            // Attempt to recover by trying to re-render the segment
            () => reset()
          }
        >
          Try again
        </SquareCta>
      </div>
    </div>
  )
}

export default ErrorHandler
